<template>
  <v-container id="manufacturerPage" class="mt-10">
    <v-row>
      <v-btn color="primary" class="mr-5" @click="save(false)">
        Сохранить
      </v-btn>
      <v-btn color="accent" @click="save(true)">Сохранить и закрыть</v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="font-weight-bold" v-text="manufacturer.name"></h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="manufacturer.name"
          label="Название"
        ></v-text-field>
        <v-text-field
          v-model="manufacturer.description"
          label="Описание"
        ></v-text-field>

        <v-snackbar v-model="snackbar">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="8">
        <v-card>
          <v-card-title>Бренды</v-card-title>
          <v-data-table :headers="headers" :items="brands">
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editBrand(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteBrand(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  name: "ManufacturerPage",
  methods: {
    init() {
      this.$http
        .get(
          `api/product/${this.$route.params.manufacturerId}`
        )
        .then((response) => {
          this.manufacturer = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getBrands() {
      this.$http
        .get(
          `api/product/${this.$route.params.manufacturerId}`
        )
        .then((response) => {
          this.brands = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getManufacturers() {
      this.$http
        .get("api/product/GetRestaurantProducts?restaurantId=55DE9A40-561E-4F44-9AFF-9A8D048165FA")
        .then((response) => {
          this.manufacturers = response.data;
        })
        .catch((error) => (this.error = error));
    },
    editBrand(item) {
      this.$router.push({
        name: "BrandPage",
        params: { brandId: item.brandId },
      });
    },
    deleteBrand(item) {
      this.$http
        .delete(`api/v1/brand/delete?brandId=${item.brandId}`)
        .then(() => {
          this.brands = this.brands.filter((brand) => {
            return item.brandId != brand.brandId;
          });
        })
        .catch((error) => {
          alert(error);
        });
    },
    save(close) {
      this.$http
        .put(`api/product/${this.$route.params.manufacturerId}`, this.manufacturer)
        .then(() => {
          if (close) {
            this.$router.push({ name: "ManufacturerList" });
          } else {
            this.snackbar = true;
            this.message = "Производитель сохранен";
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  mounted() {
    this.init();
    this.getManufacturers();
    this.getBrands();
  },
  data: () => ({
    manufacturer: {
      accountId: "",
      name: "",
    },
    snackbar: false,
    message: "",
    headers: [
      { text: "Название", value: "name" },
      { text: "Действия", value: "actions", sortable: false },
    ],
    brands: [],
  }),
};
</script>
